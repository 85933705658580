import {Injectable} from "@angular/core";
import {NgxMasonryComponent, NgxMasonryOptions} from "ngx-masonry";

@Injectable({
	providedIn: "root"
})
export class MasonryService {

	// feed div, contains all the items
	private _containerElement: NgxMasonryComponent;

	// timer for adjusting layout. Spawned after image is loaded.
	private layoutAdjustTimer: number;

	// masonry options
	private _masonryOptions: NgxMasonryOptions = {
		// percentPosition: true,
		itemSelector: ".item",
		// resize: true,
		// transitionDuration: 0
	};

	constructor() {
	}


	public adjustLayout(): void {
		this.containerElement.reloadItems();
		this.containerElement.layout();
	}

	public onImageLoaded() {
		// do not respawn if timer is present
		if (this.layoutAdjustTimer) {
			return;
		}
		// adjust layout in 300ms
		// @ts-ignore
		this.layoutAdjustTimer = setTimeout(() => {
			this.adjustLayout();
			this.layoutAdjustTimer = undefined;
		}, 300);
	}

	get masonryOptions(): NgxMasonryOptions {
		return this._masonryOptions;
	}

	get containerElement(): NgxMasonryComponent {
		return this._containerElement;
	}

	set containerElement(value: NgxMasonryComponent) {
		this._containerElement = value;
	}

}
